import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

//data
const getData = graphql`
  {
    prod: contentfulPage(modele: { eq: "productType" }) {
      subtitle
      resume {
        resume
      }
    }
  }
`
const IntroType = () => {
  const data = useStaticQuery(getData)

  return (
    <div className='intro'>
      <h2 className='blockTitle'>{data.prod.subtitle}</h2>
      <p>{data.prod.resume.resume}</p>
    </div>
  )
}

export default IntroType
