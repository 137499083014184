import React from 'react'
//component
import Seo from '../components/SEO'
import TypeHome from '../components/block/TypeHome'
//scss
import '../scss/page/index.scss'
const Index = ({ location }) => {
  return (
    <>
      <Seo title={`Accueil`} slug={location.pathname} />
      <div className='home'>
        <div className='container'>
          <TypeHome />
        </div>
      </div>
    </>
  )
}

export default Index
