import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
//content
import GetIcon from '../content/GetIcon'
//component
import IntroType from '../block/IntroType'
//data
const getData = graphql`
  {
    type: allContentfulProductType(sort: { fields: order, order: ASC }) {
      nodes {
        title
        slug
        icon {
          title
        }
      }
    }
    config: contentfulConfig {
      moreProductTitle
    }
  }
`
const TypeList = () => {
  const data = useStaticQuery(getData)
  return (
    <div className='typeList'>
      <div className='flex'>
        <IntroType />
        {data.type.nodes.map((item, index) => {
          return (
            <div key={index} className='productType'>
              <Link to={`/produits/${item.slug}`}>
                <GetIcon id={item.icon.title} />
                <span className='productTitle'>{item.title}</span>
              </Link>
            </div>
          )
        })}
      </div>
      <Link to={`/produits/`} className='button second'>
        {data.config.moreProductTitle}
      </Link>
    </div>
  )
}

export default TypeList
